import React from "react";
import PropTypes from "prop-types";
import { Translation } from "react-i18next";
import { linkClicked } from "../../api/analytics";
import PCW from "@bridge/react-components/lib/PCW";
import Button from "@cx/ui/Button";
import "./PasswordResetFormErrorArray.scss";
import TextInput from "./TextInput";
import LinkButton from "../link-button/linkButton";
import SuccessForm from "../successForm/successForm";

/**
 * Password only page.
 */
class PasswordResetFormErrorArray extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordChangeComplete: false,
      currentPassword: null,
      newPassword: "",
      newPasswordConfirmation: "",
      showAsText: false,
      validPassword: false,
      errorCurrentPassword: false,
      errorNewPassword: false,
      errorConfirmPassword: false,
      passwordErrors: []
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onClickContinue = () => {
    this.props.signinCallback(this.state.newPassword);
  };

  onKeyDown(e) {
    if (e.key === "Enter") {
      if (this.isValidForm()) {
        e.preventDefault();
        e.stopPropagation();
        this.submitForm();
      } else {
        this.setErrors();
      }
    }
  }

  isValidCurrentPassword() {
    return (
      !this.props.collectCurrentPassword || this.state.validPasswordCurrent
    );
  }

  isValidPassword() {
    return this.state.validPassword;
  }

  isValidConfirmation() {
    return this.state.newPassword === this.state.newPasswordConfirmation;
  }

  isValidForm() {
    return (
      this.isValidCurrentPassword() &&
      this.isValidPassword() &&
      this.isValidConfirmation()
    );
  }

  submitForm() {
    // Normally we would prefer to use `document.getElementById("login").submit();` but when this is called, the html form
    // specification states that it should bypass the handlers, so we will click on the submit button instead
    // see https://stackoverflow.com/a/19847255 for quick writeup of the problem
    document.getElementById("changePassword").click();
  }

  setPasswordValid = (passwordValid, errors) => {
    this.setState({ validPassword: passwordValid });
    this.setState({ passwordErrors: errors });
  };

  setErrorsIgnoreBlanks = () => {
    this.setState({
      errorCurrentPassword: !this.isValidCurrentPassword(),
      errorNewPassword:
        this.isValidCurrentPassword() &&
        !this.isValidPassword() &&
        this.state.newPassword !== "",
      errorConfirmPassword:
        this.isValidCurrentPassword() &&
        this.isValidPassword() &&
        !this.isValidConfirmation() &&
        this.state.newPasswordConfirmation !== ""
    });
  };

  setErrors = () => {
    this.setState({
      errorCurrentPassword: !this.isValidCurrentPassword(),
      errorNewPassword:
        this.isValidCurrentPassword() && !this.isValidPassword(),
      errorConfirmPassword:
        this.isValidCurrentPassword() &&
        this.isValidPassword() &&
        !this.isValidConfirmation()
    });
  };

  onLostFocusPassword = e => {
    this.validateCurrentPassword();
    this.setErrorsIgnoreBlanks();
  };

  onLostFocusPasswordConfirmation = e => {
    this.setErrorsIgnoreBlanks();
  };

  onToggleShowPassword = e => {
    e.preventDefault();
    this.setState({ showAsText: !this.state.showAsText });
  };

  validateCurrentPassword = () => {
    if (this.props.collectCurrentPassword) {
      const currentPassword = this.state.currentPassword;
      this.setState({
        validPasswordCurrent: !(
          currentPassword == null || currentPassword.trim() === ""
        )
      });
    }
  };

  onClickAbort = e => {
    e.preventDefault();
    linkClicked(
      "Abort Password Change",
      "Firing Password Abort Callback",
      this.props.reasonType
        ? `Password Reset Form (${this.props.reasonType})`
        : "Password Reset Form"
    );
    this.props.abortButtonCallback();
  };

  handleFormSubmit(e) {
    // Let's stop the form from submitting to the post endpoint
    e.preventDefault();
    linkClicked(
      "Submit Change Password Button",
      "Authenticate User",
      this.props.reasonType
        ? `Password Reset Form (${this.props.reasonType})`
        : "Password Reset Form"
    );
    this.props
      .onSubmitPassword(this.state.newPassword, this.state.currentPassword)
      .then(isSuccess => {
        this.setState({ passwordChangeComplete: isSuccess });

        // TODO: determine if error is INVALID_CURRENT_PASSWORD or FAILED_PASSWORD_CHANGE_REQUIREMENTS for
        // user-elected flow, and only clear appropriate password
        if (!isSuccess) {
          this.setState({ newPassword: "" });
          this.setState({ newPasswordConfirmation: "" });
          document.getElementById("new-password").value = "";
          if (document.getElementById("confirm-password"))
            document.getElementById("confirm-password").value = "";
          if (this.props.collectCurrentPassword) {
            document.getElementById("current-password").value = "";
          }

          if (
            this.props.passwordChangeBackendErrors &&
            this.props.passwordChangeBackendErrors.length > 0
          ) {
            this.setState({
              validPassword: false,
              errorNewPassword: true,
              passwordErrors: this.props.passwordChangeBackendErrors
            });
          }
        }
      });
  }

  renderSuccess() {
    return (
      <SuccessForm
        message={
          this.props.reasonType === "activation"
            ? "passwordResetForm.onSuccessActivation"
            : "passwordResetForm.onSuccess"
        }
        onClick={this.onClickContinue}
        solutionDisplayName={this.props.solutionDisplayName}
      />
    );
  }

  doublePasswordInputBox() {
    return (
      <Translation>
        {t => (
          <div>
            <LinkButton
              htmlId="revealPassword"
              onClick={this.onToggleShowPassword}
            >
              {this.state.showAsText
                ? t("common.hidePassword", { count: 2 })
                : t("common.showPassword", { count: 2 })}
            </LinkButton>

            <TextInput
              htmlId={"new-password"}
              label={t("passwordResetForm.newPassword.inputLabel")}
              type={this.state.showAsText ? "text" : "password"}
              error={
                this.state.errorNewPassword &&
                this.state.passwordErrors.length > 0
                  ? this.state.passwordErrors.join(`\r\n`)
                  : null
              }
              onBlur={this.onLostFocusPassword}
              onChange={e =>
                this.setState({
                  newPassword: e.target.value,
                  errorNewPassword: false,
                  errorConfirmPassword: false
                })
              }
              onKeyDown={this.onKeyDown}
            />

            <TextInput
              htmlId={"confirm-password"}
              label={t("passwordResetForm.confirmPassword.inputLabel")}
              type={this.state.showAsText ? "text" : "password"}
              error={
                this.state.errorConfirmPassword
                  ? t("passwordResetForm.confirmPassword.error")
                  : null
              }
              onBlur={this.onLostFocusPasswordConfirmation}
              onChange={e =>
                this.setState({
                  newPasswordConfirmation: e.target.value,
                  errorConfirmPassword: false
                })
              }
              onKeyDown={this.onKeyDown}
            />
          </div>
        )}
      </Translation>
    );
  }

  renderChangePasswordForm() {
    const signInButtonEnabled = this.isValidForm();
    const submitButton = (
      <Translation>
        {t => (
          <div className="button-group pull-left">
            <button
              id="changePassword"
              type="submit"
              className={"btn btn-primary"}
              disabled={!signInButtonEnabled}
            >
              {this.props.reasonType === "activation"
                ? t("passwordResetForm.buttonSetPassword")
                : t("passwordResetForm.buttonChangePassword")}
            </button>
          </div>
        )}
      </Translation>
    );

    return (
      <Translation>
        {t => (
          <div>
            <form
              id="login"
              action="/change-password"
              method="POST"
              onSubmit={this.handleFormSubmit}
            >
              {this.props.reasonKey !== undefined &&
                t("passwordResetForm.text." + this.props.reasonKey, {
                  displayName: this.props.username.toUpperCase()
                })}
              {this.props.reasonKey !== undefined && (
                <>
                  <br />
                  <br />
                </>
              )}
              <div
                className="input-wrapper username__formGroup form-group fakeHidden"
                id="username-inputWrapper"
              >
                <input
                  type="text"
                  id="username"
                  name="username"
                  autoComplete="username"
                  className="username-textInput form-control"
                  defaultValue={this.props.username} // Must be a default value so it can remain an uncontrolled component (for IE password manager)
                />
              </div>
              <div
                className="input-wrapper textInput password__formGroup form-group"
                id="password-inputWrapper"
              >
                {this.props.collectCurrentPassword && (
                  <div>
                    <TextInput
                      htmlId={"current-password"}
                      label={t("passwordResetForm.currentPassword.inputLabel")}
                      type="password"
                      error={
                        this.state.errorCurrentPassword
                          ? t("passwordResetForm.currentPassword.error")
                          : null
                      }
                      onBlur={this.onLostFocusPassword}
                      onChange={e =>
                        this.setState(
                          {
                            currentPassword: e.target.value,
                            errorNewPassword: false,
                            errorConfirmPassword: false
                          },
                          this.validateCurrentPassword
                        )
                      }
                      onKeyDown={this.onKeyDown}
                    />
                    <br />
                  </div>
                )}
                {this.doublePasswordInputBox()}
                <br />
                <div id="password-reset-form-buttons">
                  {this.props.abortButton && this.props.abortButtonCallback && (
                    <Button
                      id="abortChange"
                      buttonStyle="secondary"
                      onClick={this.onClickAbort}
                    >
                      {this.props.abortButton}
                    </Button>
                  )}
                  {submitButton}
                </div>
              </div>
            </form>
            <div className="requirementsBox">
              <PCW
                password={this.state.newPassword}
                checkValidity={this.setPasswordValid}
                mode="update"
                passwordPolicy={this.props.passwordPolicy}
                htmlId="passwordRequirementsBox"
              />
            </div>
          </div>
        )}
      </Translation>
    );
  }

  render() {
    if (this.state.passwordChangeComplete) {
      return this.renderSuccess();
    } else {
      return this.renderChangePasswordForm();
    }
  }
}

PasswordResetFormErrorArray.propTypes = {
  abortButton: PropTypes.string,
  abortButtonCallback: PropTypes.func,
  collectCurrentPassword: PropTypes.bool,
  onSubmitPassword: PropTypes.func.isRequired,
  passwordChangeBackendErrors: PropTypes.array,
  passwordPolicy: PropTypes.string,
  reasonKey: PropTypes.string,
  reasonType: PropTypes.string,
  signinCallback: PropTypes.func.isRequired,
  solutionDisplayName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default PasswordResetFormErrorArray;
